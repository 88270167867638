<template>
    <div>
      <!-- Hero Section -->
      <section class="hero">
        <div class="container">
          <h2>Why Choose Us</h2>
        </div>
      </section>
  
      <!-- Reasons Section -->
      <section class="reasons">
        <div class="container">
          <div class="reason" v-for="reason in reasons" :key="reason.id">
            <div class="reason-icon">
              <img :src="reason.icon" :alt="reason.title">
            </div>
            <div class="reason-content">
              <h3>{{ reason.title }}</h3>
              <p>{{ reason.description }}</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  </template>
  
  <script>
  export default {
    name: 'WhyChooseUs',
    data() {
      return {
        reasons: [
          {
            id: 1,
            title: 'Expertise',
            description: 'Our team consists of industry-leading experts in accessibility, ensuring top-notch solutions for your needs.',
            icon: 'Images/expertise.jpg'
          },
          {
            id: 2,
            title: 'Comprehensive Services',
            description: 'From audits to training, we offer a full range of services to help you achieve and maintain accessibility compliance.',
            icon: 'Images/service.jpg'
          },
          {
            id: 3,
            title: 'Customer Success',
            description: 'We prioritize our clients’ success, providing dedicated support and customized solutions tailored to your unique needs.',
            icon: 'Images/success.png'
          },
          {
            id: 4,
            title: 'Commitment to Accessibility',
            description: 'Our mission is to create a more inclusive digital world by making accessibility a standard practice for everyone.',
            icon: 'Images/accessibility.png'
          }
        ]
      };
    },
  };
  </script>
  
  <style scoped>
  /* General styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  /* Hero Section */
  .hero {
    border-top: 1px solid burlywood;
    background-color: rgb(255, 255, 255);
    color: rgb(30, 4, 72);
    padding: 1em 1em;
    text-align: center;
  }
  
  .hero .container {
    margin: 0 auto;
  }
  
  .hero h1 {
    font-size: 2.5em;
    margin: 0;
  }
  
  .hero p {
    font-size: 1.2em;
    margin: 0.5em 0 1.5em;
  }
  
  /* Reasons Section */
  .reasons {
    background-color: #f9f9f9;
    padding: 2em 1em;
  }
  
  .reasons .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .reason {
    display: flex;
    max-width: 500px;
    margin: 1em;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .reason-icon {
    padding: 1em;
    background-color: #eef1f5;
  }
  
  .reason-icon img {
    max-width: 64px;
    max-height: 64px;
  }
  
  .reason-content {
    padding: 1.5em;
  }
  
  .reason-content h2 {
    font-size: 1.5em;
    margin: 0.5em 0;
  }
  
  .reason-content p {
    font-size: 1em;
    margin: 0.5em 0;
  }
  
  /* Call to Action Section */
  .cta {
    background-color: #1a73e8;
    color: white;
    padding: 3em 1em;
    text-align: center;
  }
  
  .cta .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cta h2 {
    font-size: 2em;
    margin: 0.5em 0;
  }
  
  .cta p {
    font-size: 1.2em;
    margin: 0.5em 0;
  }
  
  .cta button {
    background-color: #ffcc00;
    color: #1a73e8;
    border: none;
    padding: 0.75em 1.5em;
    cursor: pointer;
    font-size: 1em;
    border-radius: 0.25em;
  }
  
  .cta button:hover {
    background-color: #e6b800;
  }
  </style>
  