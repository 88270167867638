<template>
  <div class="home">
    <MainContent />
    <ChooseUs />
  </div>
</template>

<script>
// @ is an alias to /src
import MainContent from '@/components/MainContent.vue'
import ChooseUs from '@/components/ChooseUs.vue'

export default {
  name: 'HomeView',
  components: {
    MainContent,
    ChooseUs
  }
}
</script>
