<template>
  <header class="navbar">
    <div class="brand-title">
      <router-link to="/"><img src="Images/univer.png" alt="Universal Approach"></router-link>
    </div>
    <nav>
      <button aria-label="Site navigation" class="toggle-button" @click="toggleMenu" :aria-expanded="isActive" ref="toggleButton">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
      <div :class="['navbar-links', { active: isActive }]" id="navbar-links">
        <ul>
          <li><router-link to="/" exact @click="closeMenu">Home</router-link></li>
          <li><router-link to="/service" @click="closeMenu">Service</router-link></li>
          <li><router-link to="/resource" @click="closeMenu">Resource</router-link></li>
          <li><router-link to="/article" @click="closeMenu">Articles</router-link></li>
          <li><router-link to="/checklist" @click="closeMenu">Checklist</router-link></li>
          <li><router-link to="/pdf" @click="closeMenu">PDF</router-link></li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'NavMenu',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    closeMenu() {
      this.isActive = false;
      this.$refs.toggleButton.focus()
   },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Other styles */
a > img {
  height: 4rem;
  width: 14rem;
  margin-bottom: -0.5rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003349;
  padding: 1rem;
  position: relative;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: bold;
}

.navbar-links a:hover {
  background-color: #555;
  border-radius: 4px;
}

.router-link-active {
  border-bottom: 1px solid white;
}

.toggle-button {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.toggle-button .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .toggle-button {
    display: flex;
  }

  .navbar-links {
    position: absolute;
    margin-top: 0.2rem;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #2d16da;
    display: none;
    width: 100%;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links ul {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .navbar-links li {
    text-align: center;
    margin: 1rem 0;
  }

  .navbar-links a {
    padding: 0.5rem;
    width: 100%;
  }
}
</style>

