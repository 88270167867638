<template>
    <div>
      <!-- Hero Section -->
      <section class="second">
        <h1>We Are Pioneer In Accessibility</h1>
        <p>Empowering individuals and organizations to create accessible digital content.</p>
      </section>
      <section class="hero" >
          <img :src="image" alt="people with disabilities">
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    data(){
      return {
        image: "Images/banner.jpg"
      }
    },
    methods: {
      learnMore() {
        alert('Learn more about our services!');
      }
    }
  };
  </script>
  
  <style scoped>
  /* General styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  }
  .hero {
    margin-bottom: -0.5rem;
  }
  img{
    width: 100%;
    height: auto;
    z-index: -1;
  }
  .second {
    background-color: lightblue;
    padding: 1rem 0;
  }
  h1 { 
    font-size: 2.5rem;
    margin: 0.5rem 0;
    text-align: center;
  }
  p {
    font-size: 1.2em;
    margin: 0.5em 0 1.5em;
    text-align: center;
  }

  .features {
    background-color: #f9f9f9;
    padding: 2em 1em;
  }
  
  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    max-width: 300px;
    text-align: center;
    margin: 1em;
  }
  
  .feature h2 {
    font-size: 1.5em;
    margin: 0.5em 0;
  }
  
  .feature p {
    font-size: 1em;
    margin: 0.5em 0;
  }
  </style>
  