<template>
    <footer class="footer">
      <div class="container">
        <div class="footer-top">
          <div class="footer-section">
            <h4>Company</h4>
            <ul>
              <li><router-link to="/about">About Us</router-link></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Press</a></li>
                <li><router-link to="/blog">Blog</router-link></li>
            </ul>
          </div>
          <div class="footer-section">
            <h4>Resources</h4>
            <ul>
              <li><router-link to="/source">Docs</router-link></li>
              <li><a href="#">API</a></li>
              <li><a href="#">Community</a></li>
              <li><a href="https://universalapproach.org/#/contact">Support</a></li>
            </ul>
          </div>
          <div class="footer-section">
            <h4>Legal</h4>
            <ul>
              <li><router-link to="/terms">Terms of Service</router-link></li>
              <li><router-link to="/privacy">Privacy Policy</router-link></li>
              <li><a href="#">Cookie Policy</a></li>
              <li><a href="#">GDPR Compliance</a></li>
            </ul>
          </div>
          <div class="footer-section">
            <h4>Contact Us</h4>
            <ul>
              <li><a href="mailto:rjannat@universalapproach.org">Email Us</a></li>
              <li><a href="https://universalapproach.org/#/contact">Support</a></li>
                <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
        </div>
        <div class="footer-bottom">
          <p>&copy; 2024 Universal Approach Llc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    data(){
      return {
        image: "Images/call.jpeg"
      }
    },
    name: 'FooterContent'
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #333;
    color: white;
    padding: 2em 1em;
    text-align: center;
  }
  img {
    display: inline-block;
    width: 1rem;
    height: .8rem;
  }
  
  .footer .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }
  
  .footer-section {
    max-width: 200px;
    margin: 1em 0;
  }
  
  .footer-section h4 {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5em;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
    font-size: 0.9em;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 1em;
    font-size: 0.8em;
  }
  </style>
  
