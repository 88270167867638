import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import NavMenu from './components/NavMenu.vue';
import FooterContent from './components/FooterContent.vue';


const app = createApp(App)

app.component('nav-menu', NavMenu);
app.component('footer-content', FooterContent);
app.use(router)
app.mount('#app')
